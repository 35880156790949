<template>
    <div class="container">
        <div class="columns is-centered content is-small title">
            <div class="column is-5-tablet is-4-desktop is-5-widescreen">
                <h2 class="mb-5 "> Verify Your Email Address</h2>

                <div class="columns is-centered pt-4">
                    <p>Please check your email for a verification link.</p>
                </div>
                <div class="columns is-centered pt-4">
                    <router-link class="button is-white is-four-fifths has-text-weight-semibold" :to="{path: goTo(), query: $storage.get('last_page_params')}">Back to <span class="page">{{goToName()}}</span></router-link>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "VerifyNoticeScreen",
        methods:{
            goTo(){
                return this.$storage.get('last_page') ? this.$storage.get('last_page'): 'home';
            },
            goToName(){
                if (this.$storage.get('last_page') && this.$storage.get('last_page').split('/')[1]){
                    return this.$storage.get('last_page').split('/')[1]
                }else{
                    return 'home'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page{
        text-transform: capitalize;
    }
    .container{
        margin: 150px auto;
    }
    .entertainment {
        body {
            background-color: black !important;
        }
        .title {
            padding-top: 10rem;
            color: #d0d0d0;
            h2{
                color: #F5F5F5;
            }
        }
        .container {
            margin: 80px auto 0;
        }
    }
</style>
